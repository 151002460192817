import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import componentStyles from "./development.module.scss"
import DevelopmentIllustration from "../../assets/images/development-illustration.svg"
import Button from "../../components/Button/Button"
import { servicesList } from "../../hooks"
import services from "../services.module.scss"
import PresentationForm from "../../components/PresentationForm/PresentationForm"

const firstListItems = [
  "services.development.first-block.list.first-text",
  "services.development.first-block.list.second-text",
  "services.development.first-block.list.third-text",
  "services.development.first-block.list.forth-text",
  "services.development.first-block.list.fifth-text",
  "services.development.first-block.list.sixth-text",
  "services.development.first-block.list.seventh-text",
]

const secondListItems = [
  "services.development.second-block.list.first",
  "services.development.second-block.list.second",
  "services.development.second-block.list.third",
  "services.development.second-block.list.forth",
  "services.development.second-block.list.fifth",
  "services.development.second-block.list.sixth",
  "services.development.second-block.list.seventh",
  "services.development.second-block.list.eighth",
]

const Development: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()
  const serviceList = servicesList
  const bannerClasses = classNames(componentStyles.banner)
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
    "grid-2"
  )
  const developmentClasses = classNames(
    componentStyles.development,
    "container"
  )
  const firstListClasses = classNames(
    componentStyles.developmentBlockFirstList,
    "grid-4"
  )
  const secondListClasses = classNames(
    componentStyles.developmentSecondBlockList
  )
  const projectsTitleClasses = classNames(
    componentStyles.developmentProjectsTitle,
    "large-section-title"
  )
  const blockInServices = classNames(services.blockInServices, "container")
  const blockInServicesList = classNames(services.blockInServicesList, "grid-3")

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "services.development.seo.description",
        })}
        title={intl.formatMessage({
          id: "services.development.seo.title",
        })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <h1 className={componentStyles.bannerTitle}>
            {intl.formatMessage({
              id: "services.development.banner-title",
            })}
          </h1>
          <div className={componentStyles.bannerImage}>
            <img
              className={componentStyles.bannerImageDevCube}
              src="/icons/dev-cube.svg"
              alt="Orange graphic cube image"
              width="72"
              height="92"
            />
            <img
              className={componentStyles.bannerImageMainImage}
              src={DevelopmentIllustration}
              alt="Illustration with people behind the table and dashboards"
              width="417"
              height="325"
            />
          </div>
          <div>
            <div className={componentStyles.bannerText}>
              {intl.formatMessage({
                id: "services.development.banner-text",
              })}
            </div>
            <PresentationForm/>
          </div>
        </div>
      </section>
      <section className={developmentClasses}>
        <div className={componentStyles.developmentBlock}>
          <h4>
            {intl.formatMessage({
              id: "services.development.first-block.title",
            })}
          </h4>
          <p>
            {intl.formatMessage({
              id: "services.development.first-block.text",
            })}
          </p>
        </div>
        <ol className={firstListClasses}>
          {firstListItems.map(translatePath => (
            <li
              key={translatePath}
              className={componentStyles.developmentBlockFirstListItem}
            >
              <p className={componentStyles.developmentBlockFirstListItemText}>
                {intl.formatMessage({
                  id: translatePath,
                })}
              </p>
            </li>
          ))}
        </ol>
      </section>
      <section className={developmentClasses}>
        <div className={componentStyles.developmentSecondBlock}>
          <h4>
            {intl.formatMessage({
              id: "services.development.second-block.title",
            })}
          </h4>
          <p>
            {intl.formatMessage({
              id: "services.development.second-block.text",
            })}
          </p>
        </div>
        <ul className={secondListClasses}>
          {secondListItems.map(translatePath => (
            <li
              key={translatePath}
              className={componentStyles.developmentSecondBlockListItem}
            >
              <p>
                {intl.formatMessage({
                  id: translatePath,
                })}
              </p>
            </li>
          ))}
        </ul>
      </section>
      <section className={developmentClasses}>
        <div className={componentStyles.developmentLastBlock}>
          <h4>
            {intl.formatMessage({
              id: "services.development.third-block.title",
            })}
          </h4>
          <p>
            {intl.formatMessage({
              id: "services.development.third-block.text",
            })}
          </p>
        </div>
        <table className={componentStyles.developmentLastBlockTable}>
          <tbody>
            <tr>
              <td>
                {intl.formatMessage({
                  id: "services.development.third-block.list.first",
                })}
              </td>
              <td />
            </tr>
            <tr>
              <td>
                {intl.formatMessage({
                  id: "services.development.third-block.list.second",
                })}
              </td>
              <td>
                {intl.formatMessage({
                  id: "services.development.third-block.list.sixth",
                })}
              </td>
            </tr>
            <tr>
              <td>
                {intl.formatMessage({
                  id: "services.development.third-block.list.third",
                })}
              </td>
              <td>
                {intl.formatMessage({
                  id: "services.development.third-block.list.seventh",
                })}
              </td>
            </tr>
            <tr>
              <td>
                {intl.formatMessage({
                  id: "services.development.third-block.list.forth",
                })}
              </td>
              <td>
                {intl.formatMessage({
                  id: "services.development.third-block.list.eight",
                })}
              </td>
            </tr>
            <tr>
              <td>
                {intl.formatMessage({
                  id: "services.development.third-block.list.fifth",
                })}
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </section>
      <section className={blockInServices}>
        <h2 className={projectsTitleClasses}>
          {intl.formatMessage({ id: "index.services.top-title" })}
        </h2>
        <ul className={blockInServicesList}>
          {serviceList.map(({ title, link }) => {
            const path = "/services/" + link
            if (
              "/" + intl.locale + path !== location.pathname &&
              "/" + intl.locale + path + "/" !== location.pathname
            ) {
              return (
                <li key={title} className={services.blockInServicesListItem}>
                  <h3 className={services.blockInServicesListItemTitle}>
                    {intl.formatMessage({ id: title })}
                  </h3>
                  <Button
                    to={path}
                    className={services.blockInServicesListButton}
                  >
                    {intl.formatMessage({ id: "services.button-title" })}
                  </Button>
                </li>
              )
            }
          })}
        </ul>
      </section>
    </Layout>
  )
}
export default Development
