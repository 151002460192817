import { useLocation } from "@reach/router";
import { useFormik } from "formik"
import { useIntl } from "gatsby-plugin-intl"
import { throttle } from "lodash"
import { useCallback, useEffect } from "react"
import * as yup from "yup"
import { useSiteMetadata } from "./useSiteMetadata"
import { useTracking } from './useTracking'
import { TrackingEvent } from '../models/TrackingEvent.enum'

interface PresentationFormParams {
    emailValue: string,
    errorMsg?: string | null,
    dirty: boolean,
    isSubmitting: boolean,
    onEmailChange: React.ChangeEventHandler<HTMLInputElement>,
    setSubmitting: (isSubmitting: boolean) => void;
    onCloseForm;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void
}
async function getIP():Promise<String | undefined> {
  let res = await fetch('https://api.db-ip.com/v2/free/self')
  const data = await res.json()
  return data.ipAddress
}
const notCorporateEmailDomains = ['gmail.com', 'yandex.ru', 'hotmail.com', 'yahoo.com', 'aol.com'];

const usePresentationForm = ():PresentationFormParams => {
    const intl = useIntl()
    const { siteUrl } = useSiteMetadata()
    const location = useLocation();
    const { track } = useTracking()

    const validationObject = {
      email: yup
        .string()

      .email("contact-form.fields.email.errors.incorrect")
      .test(
        "is-corporate-email",
        "Please, enter business email only",
        (value) => {
          if (!value) {
            return false;
          }
          const [, domain] = value.split("@");
          return !notCorporateEmailDomains.includes(domain);
        }
      )
        .required("contact-form.fields.email.errors.incorrect"),
    }
    const formik = useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: yup.object().shape(validationObject),
      validateOnBlur: true,
      validateOnChange: true,
      onSubmit: async values => {
        const userIP = await getIP();
        const props = {
          ...values,
          siteUrl,
          source: "Presentation form",
          urlFrom: location.href,
          UserIP: userIP,
        }

        track(TrackingEvent.PRESENTATION_FORM_SUBMIT, props)
        fetch("https://formcarry.com/s/YI6ofpUzIKZ", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(props),
        })
          .then(response => {
            if (response.status === 200) {
              console.log('Form was successfully sent')
              formik.setSubmitting(true)
            }
          })
          .catch(error => {
            formik.setSubmitting(false)
            console.log('Form wasn\'t submitted with error' + error)
          })
      },
    })
    const throttledEmailChange = useCallback(
      throttle((event) => {
        track(TrackingEvent.PRESENTATION_FORM_CHANGE_EMAIL, {
          email: event?.target?.value || '',
        })
      }, 4000),
      [],
    )
    const onEmailChange = event => {
      throttledEmailChange(event)
      formik.handleChange(event)
    }

    const onCloseForm = () => {
      formik.resetForm()
      formik.setValues({email: ""})
    }

    const errorMsg = formik.touched["email"] && formik.errors["email"] && formik.dirty
                    ? intl.formatMessage({ id: formik.errors["email"] })
                    : null
    return {
        emailValue: formik.values.email,
        errorMsg,
        dirty: formik.dirty,
        isSubmitting: formik.isSubmitting,
        onEmailChange,
        handleSubmit: formik.handleSubmit,
        setSubmitting: formik.setSubmitting,
        onCloseForm
    }
}

export default usePresentationForm