import React from "react"
import componentStyles from "./PresentationForm.module.scss"
import Button from "../Button/Button"
import { useIntl } from "gatsby-plugin-intl"
import Input from "../Input/Input"
import usePresentationForm from "../../hooks/usePresentationForm"

interface PresentationFormType {}

const PresentationForm: React.FC<PresentationFormType> = () => {
  const intl = useIntl()
  const {
    emailValue,
    errorMsg,
    isSubmitting,
    onEmailChange,
    handleSubmit
  } = usePresentationForm()

  if (isSubmitting) {
    return (
      <div className={componentStyles.presentationFormMessage}>
        <h2>
          {intl.formatMessage({ id: "contact-form.submission.title" })}
        </h2>
      </div>
    )
  }

  return (
    <div>
      <div className={componentStyles.presentationFormDescribeText}>
        {intl.formatMessage({ id: "index.banner.describe-form" })}
      </div>
      <form className={componentStyles.presentationForm}
        onSubmit={handleSubmit}
      >
        <Input
          name="email"
          type="email"
          errorMsg={errorMsg}
          value={emailValue}
          placeholder={intl.formatMessage({ id: "index.banner.placeholder" })}
          className={componentStyles.presentationFormInput}
          onChange={onEmailChange}/>
        <Button
          color="orange"
          filled={true}
          type="submit"
          className={componentStyles.presentationFormSubmit}
        >
          {intl.formatMessage({ id: "index.banner.submit" })}
        </Button>
      </form>
    </div>
  )
}

export default PresentationForm
