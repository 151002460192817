import React, { InputHTMLAttributes} from "react"
import componentStyles from "./Input.module.scss"
import classNames from "classnames"

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  errorMsg: string | null,
}

const Input: React.FC<InputProps> = ({ type="text", errorMsg, className, ...rest }) => {

  return (
    <div className={classNames(componentStyles.container, className)}>
      <input
        {...rest}
        className={componentStyles.input}
        type={type}
      />
      {errorMsg && <span className={componentStyles.errorMessage}>
        {errorMsg}
      </span>}
    </div>
  );
};

export default Input
