import { useIntl } from 'gatsby-plugin-intl'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

// the simplest way to keep this value as global to avoid reset on rerender
let isInited = false

const useTracking = (isDebugMode?) => {
    const intl = useIntl()
    const mixpanel = useMixpanel()

    function init() {
        if (!isInited) {
            isInited = true
            mixpanel.register({
                browserZoom: `${Math.round(getZoomLevel() * 100)}%`,
                isTouchScreen: getIsTouchScreen(),
                languageInUI: intl.locale,
            })
        }
    }

    function getIsRetinaDisplay() {
        const w = window

        return ((w.matchMedia && (w.matchMedia('only screen and (min-resolution: 124dpi), ' +
            'only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches ||
            w.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), ' +
                'only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), ' +
                'only screen and (min-device-pixel-ratio: 1.3)').matches)) ||
            (w.devicePixelRatio && w.devicePixelRatio > 1.3))
    }

    function getIsTouchScreen() {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0))
    }

    function getZoomLevel() {
        const pixelRatio = getIsRetinaDisplay() ? 2 : 1

        return Math.round(window.devicePixelRatio * 100) / pixelRatio / 100 || pixelRatio
    }

    function track(eventName: string, eventProperties = {}, options = {}, callback?) {
        if (isDebugMode) {
            console.log(
                JSON.stringify({
                    eventName,
                    eventProperties,
                })
            )
            if (callback) {
                callback()
            }
            return
        }

        if (isInited) {
            mixpanel.track(eventName, eventProperties, options, callback)
        }
    }

    return {
        init,
        track,
    }
}

export { useTracking }
